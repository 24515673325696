/**************************************************************************************
	Removing the underline from links makes it hard for color-blind users to see them.
***************************************************************************************/
/* core/libraries/jquery.mmenu/assets/7.0.3/jquery.mmenu.all.min.css */
.mm-iconbar__bottom a,
.mm-iconbar__bottom a:hover,
.mm-iconbar__top a,
.mm-iconbar__top a:hover {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.mm-sectionindexer a {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

/**************************************************************************************
	This CSS style makes it difficult or impossible to see the dotted link focus outline.
***************************************************************************************/
/* www/assets/stylesheets/components.css */
.w-input:focus,
.w-select:focus {
	outline: inherit;
}
