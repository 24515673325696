body {
	font-family: Oswald, sans-serif;
	color: #444;
	font-size: 1rem;
	line-height: 1.4;
	font-weight: 400;
}

h1 {
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	color: #282c2d;
	font-size: 2.25rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #282c2d;
	font-size: 1.875rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #282c2d;
	font-size: 1.5rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #282c2d;
	font-size: 1.25rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	color: #282c2d;
	font-size: 0.9375rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	color: #282c2d;
	font-size: 0.8125rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

p {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-family: Oswald, sans-serif;
	color: #444;
	line-height: 1.5rem;
	font-weight: 400;
}

body a,
.link {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	line-height: 1.5rem;
	text-decoration: none;
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
	color: #e31d38;
	font-size: 1rem;
}

body a:hover,
.link:hover {
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
	color: #5b5e5f;
}

a.w--current,
.link.w--current {
	color: #28313b;
	font-weight: 700;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.25rem;
	font-family: Oswald, sans-serif;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.125rem;
}

li {
	margin-bottom: 0.25rem;
	font-family: Oswald, sans-serif;
}

label {
	display: block;
	margin-bottom: 0.25rem;
	line-height: 1.25rem;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02rem;
}

em {
	font-style: italic;
	letter-spacing: 0.02rem;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1.5rem 2rem;
	border-left: 4px solid rgba(0, 0, 0, 0.5);
	background-color: #ecf4f6;
}

blockquote p {
	font-family: Oswald, sans-serif;
	font-size: 1.125rem;
	line-height: 1.8rem;
	font-weight: 500;
}

blockquote p:last-of-type {
	margin-bottom: 0;
}

.image-link {
	border: 0;
}


/***************************************
  buttons
****************************************/
.primary,
.secondary,
.tertiary,
button[type="submit"],
.cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button,
#submissionforms_module.cms_form .cms_buttons input.cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1.5rem;
	background-color: #e31d38;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Oswald, sans-serif;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	cursor: pointer;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover,
#submissionforms_module.cms_form .cms_buttons input.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.button:hover {
	background-color: #000;
	color: #fff;
	cursor: pointer;
}

.secondary,
input.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons input.cms_form_button.secondary,
.button.secondary {
	background-color: #000;
	cursor: pointer;
}

.secondary:hover,
input.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons input.cms_form_button.secondary:hover,
.button.secondary:hover {
	background-color: #e31d38;
	cursor: pointer;
}

.tertiary,
.button.tertiary {
	padding-right: 1rem;
	padding-left: 1rem;
	background-color: #5b5e5f;
	font-size: 0.85rem;
	line-height: 1.5em;
}

.tertiary:hover,
.button.tertiary:hover {
	background-color: #4e5152;
	cursor: pointer;
}

.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 2rem;
	height: 100%;
	min-height: 2rem;
	margin-top: 0em;
	margin-right: 0px;
	margin-bottom: 0em;
	padding: 0em 1.25rem;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border: 2px solid transparent;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url('/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1rem;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

/***************************************
  forms
****************************************/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	color: #333;
	border: 1px solid #ccc;
}

textarea {
	resize: vertical;
}
/* end of forms */

.container {
	display: block;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
}

.container.about-us {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	margin-top: -100px;
	padding: 2.5rem 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.container.projects {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.careers {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	background-image: url('/assets/images/graph-paper-texture-2.svg');
	background-position: 0px 0px;
	background-size: 665px 665px;
}

.container.testimonials-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	padding-top: 2.25rem;
	padding-bottom: 2.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	background-image: url('/assets/images/graph-paper-texture-2.svg');
	background-position: 50% 50%;
	background-size: 665px 665px;
	background-repeat: repeat;
}

.container.careers-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	background-image: url('/assets/images/graph-paper-texture-2.svg');
	background-position: 0px 0px;
	background-size: 500px;
}

.nav-section {
	width: 100%;
	background-color: transparent;
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 44px;
	max-width: 100px;
	margin-right: 0px;
	padding: 0.8rem 3.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-color: transparent;
	color: #525f94;
	font-size: 0.85rem;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
}

.nav-link:hover {
	background-color: rgba(32, 108, 119, 0.25);
	cursor: pointer;
}

.nav-link.w--current {
	padding: 0.75rem 1.15rem 0.6rem;
	border-bottom: 2px solid #3aa1af;
	background-color: #f0f5f6;
	box-shadow: inset 1px 0 0 0 #fff, inset -1px 0 0 0 #fff;
	color: #00214b;
	font-size: 0.85rem;
	font-weight: 600;
}

.nav-link.w--current:hover {
	border-bottom-color: #3aa1af;
	background-color: #ebf2f3;
	box-shadow: none;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 550px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px none hsla(0, 0%, 50%, 0.25);
}

.logo-garling-link {
	z-index: 5;
	width: 315px;
	min-width: 215px;
	margin-top: 5rem;
	margin-bottom: 0rem;
	padding: 0rem 2rem 2rem;
	border-bottom-style: none;
	background-color: #fff;
	box-shadow: none;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.logo-garling-link:hover {
	color: #fff;
	cursor: pointer;
}

.logo-garling-link {
	width: 315px;
	min-width: 215px;
	margin-top: 12rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 6px 0 15px -4px rgba(0, 0, 0, 0.15), -6px 0 15px -4px rgba(0, 0, 0, 0.15);
}

.footer-section {
	position: relative;
	background-color: #333738;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.row.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.column {
	width: 50%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	float: left;
}

.column.signoff {
	padding-right: 0rem;
	padding-left: 0rem;
}

.column.full {
	width: 100%;
	padding-top: 0.5rem;
}

.column.full.about-us {
	width: 100%;
	padding-right: 0.5rem;
}

.column.half {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0.5rem;
	padding-right: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.column.half._1 {
	min-height: 350px;
	padding: 2.5rem 2rem;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/assets/images/832105172-170667a.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/assets/images/832105172-170667a.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.column.half._2 {
	min-height: 350px;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.column.half._2 {
	min-height: 350px;
	padding: 2.5rem 2rem;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/assets/images/marion_library.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/assets/images/marion_library.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.column.full {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0.5rem;
	padding-right: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.column.full._1 {
	width: 100%;
	min-height: 350px;
	margin-bottom: 6rem;
	padding: 2.5rem 2rem;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/assets/images/careers_1900x1286.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/assets/images/careers_1900x1286.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.garling-logo {
	width: 250px;
	padding-top: 5rem;
	padding-top: 0;
	-webkit-transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	margin-top: -25px;
}

.footer-signoff-section {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: #151515;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	width: 100%;
	margin-right: 0.75rem;
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 0rem;
	font-family: Oswald, sans-serif;
	color: #c0c8cf;
	font-size: 0.8rem;
	font-weight: 400;
	text-transform: none;
}

.footer-signoff-list {
	display: inline-block;
	width: 100%;
	margin-top: 0rem;
	margin-right: 0.25rem;
	margin-bottom: 0.5rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0rem;
	padding-right: 0.5rem;
	padding-left: 0rem;
}

.footer-signoff-list-item.terms {
	display: none;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	padding-left: 32px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-image: url('/assets/images/globalreach_knockout_fullwhite-optimized.svg');
	background-size: 130px 28px;
	background-repeat: no-repeat;
	opacity: 0.8;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	opacity: 0.95;
	color: #606060;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 55%;
	clear: both;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-wrap._2 {
	width: 10%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-column-wrap._3 {
	width: 10%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-column-wrap.offices {
	width: 25%;
	max-width: 65%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-column-title {
	margin-top: 0.5em;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
}

.header-content-right-wrap {
	display: none;
	margin-right: 1em;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 120px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 100%, 0.8);
	box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1em;
	padding-left: 0px;
	font-family: Oswald, sans-serif;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 65%;
	height: 100%;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.header-search-field {
	width: 2rem;
	height: 2.25rem;
	margin-bottom: 0em;
	padding: 0.5rem 1rem;
	float: left;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border: 0px none transparent;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	background-image: linear-gradient(244deg, #1b2f63, #3aa1af);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	border: 0px none transparent;
	background-color: rgba(0, 0, 0, 0.3);
}

div#message.success,
.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #3e6b29;
	font-family: Oswald, sans-serif;
	color: #fff;
}

div#message.error,
.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #e31d38;
	font-family: Oswald, sans-serif;
	color: #fff;
}

div#message p {
	font-family: Oswald, sans-serif;
	color: #fff;
}

div#message > *:last-of-type {
	margin-bottom: 0;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.secondary-nav-list-item.first {
	border-left-style: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-navigation-column {
	padding-left: 0px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
}

.left-nav-list-item {
	margin-bottom: 1px;
}

.left-nav-list-link {
	display: block;
	margin-bottom: 1px;
	padding: 0.75em 1.5rem 0.75em 0.9rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #5b5e5f;
	font-weight: 600;
	border-bottom: 0;
}

.left-nav-list-link:hover {
	color: #e31d38;
	cursor: pointer;
}

.left-nav-list-link.selected,
.left-nav-list-link.w--current {
	margin-bottom: 1px;
	background-color: #f4f7fb;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	box-shadow: inset 4px 0 0 0 hsla(0, 0%, 73%, 0.65);
	color: #c11930;
}

.left-nav-list-link.section-title {
	background-color: #5b5e5f;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1em;
	text-transform: uppercase;
	border-bottom: 0;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.left-nav-nested-list-link {
	display: block;
	margin-bottom: 1px;
	padding: 0.75em 1.5rem 0.75em 1.75rem;
	background-color: transparent;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #5b5e5f;
	font-size: 0.8125em;
	line-height: 1.375em;
	font-weight: 600;
	border-bottom: 0;
}

.left-nav-nested-list-link:hover {
	color: #e31d38;
	cursor: pointer;
}

.left-nav-nested-list-link.selected,
.left-nav-nested-list-link.w--current {
	background-color: #f4f7fb;
	box-shadow: inset 4px 0 0 0 hsla(0, 0%, 73%, 0.65);
	color: #c11930;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	background-color: #fff;
	background-position: 19px 50%;
	color: #5b5e5f;
	cursor: pointer;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #c11930;
	cursor: pointer;
}

.left-nav-nested-list-link.great-grandchild.selected,
.left-nav-nested-list-link.great-grandchild.w--current {
	background-color: #f4f7fb;
	color: #c11930;
	cursor: pointer;
}

.right-coloumn-div .box,
.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
}

.sidebar-section-title.h2 {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	letter-spacing: 0.01rem;
}

.sidebar-section-title.h3 {
	font-size: 1.25rem;
	line-height: 1.775rem;
	letter-spacing: 0.01rem;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	padding-top: 10rem;
	background-color: hsla(200, 4%, 86%, 0.85);
	background-image: url('/assets/images/test-2-graph-paper-texture-white-02-01.svg');
	background-position: 0px 0px;
	background-size: 100%;
	background-repeat: repeat;
}

.secondary-nav-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-text.indicator-text {
	position: relative;
	margin-left: 0.25rem;
	padding: 3px 5px;
	border-radius: 2px;
	background-color: rgba(33, 41, 49, 0.27);
	font-size: 0.5625rem;
	line-height: 1.25em;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	color: #fff;
	cursor: pointer;
}

.slider-section {
	position: relative;
	background-color: #373a3d;
}

.home-section {
	position: relative;
	z-index: 1001;
}

.home-content-box-wrap {
	width: 100%;
}

.home-content-box-wrap.products {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: hsla(0, 0%, 100%, 0.3);
}

.home-cbox-learn-more {
	display: inline-block;
	margin-top: 0.5rem;
	padding-bottom: 0.25rem;
	clear: both;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	border-bottom: 2px solid hsla(93, 16%, 68%, 0.31);
	font-family: Oswald, sans-serif;
	color: #e31d38;
	font-size: 1rem;
	font-weight: 600;
	text-transform: none;
}

.home-cbox-learn-more:hover {
	color: #898f90;
	cursor: pointer;
	border-bottom: 2px solid rgba(172, 186, 160, 0.31);
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5rem;
	padding-bottom: 0.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	background-color: #fff;
}

.inside-content-beckground.inside-right {
	padding-right: 2rem;
	padding-left: 2rem;
}

.inside-content-beckground.inside-both {
	padding-right: 2rem;
	padding-left: 2rem;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.intro_text_style,
.intro-text-style {
	font-family: Oswald, sans-serif;
	font-size: 1.1875em;
	line-height: 1.5em;
}

.wide-background-block {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.wide-background-block.content-box-background {
	max-width: 100%;
	border-bottom: 3px solid #eaecec;
	background-color: transparent;
	color: #fff;
}

.footer-links-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -0.25em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-top: 1px none hsla(0, 0%, 100%, 0.1);
}

.footer-links-list-item {
	box-shadow: none;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 0.5em;
	padding-bottom: 1.5em;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.footer-link {
	color: #8ec8e4;
	font-size: 0.875rem;
}

.footer-link:hover {
	color: #bef2ff;
	cursor: pointer;
}

.footer-link.footer-list-item-link {
	display: block;
	padding-top: 0.15em;
	padding-bottom: 0.15em;
	border-bottom: 1.5px solid hsla(0, 0%, 100%, 0.14);
	color: #bababa;
	font-size: 0.875rem;
}

.footer-link.footer-list-item-link:hover {
	color: #fff;
	cursor: pointer;
}

.footer-link.signoff {
	padding-bottom: 0.075rem;
	border-bottom: 1px solid rgba(91, 94, 95, 0.5);
	color: #898f90;
	font-size: 0.8rem;
}

.footer-link.signoff:hover {
	color: #dadcdd;
	cursor: pointer;
}

a.pagination-link,
.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

a.pagination-link.next,
.link.pagination-link.next {
	float: right;
}

a.breadcrumb-link,
.link.breadcrumb-link {
	color: #5b5e5f;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
	letter-spacing: 1px;
}

h1 a,
.link.h1-link {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #e31d38;
	font-size: 2.25rem;
	line-height: 1.4;
}

h1 a:hover,
.link.h1-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #5b5e5f;
	cursor: pointer;
}

h2 a,
.link.h2-link {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #e31d38;
	font-size: 1.875rem;
	line-height: 1.4;
}

h2 a:hover,
.link.h2-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #5b5e5f;
	cursor: pointer;
}

h3 a,
.link.h3-link {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #5b5e5f;
	font-size: 1.5rem;
	line-height: 1.4;
}

h3 a:hover,
.link.h3-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #e31d38;
	cursor: pointer;
}

h4 a,
.link.h4-link {
	color: #5b5e5f;
	font-size: 1.25rem;
	line-height: 1.4;
}

h4 a:hover,
.link.h4-link:hover {
	color: #e31d38;
	cursor: pointer;
}

h5 a,
.link.h5-link {
	color: #5b5e5f;
	cursor: pointer;
}

h5 a:hover,
.link.h5-link:hover {
	color: #e31d38;
	cursor: pointer;
}

h6 a,
.link.h6-link {
	color: #5b5e5f;
	font-size: 0.8125rem;
	line-height: 1.4;
}

h6 a:hover,
.link.h6-link:hover {
	color: #e31d38;
	cursor: pointer;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.15);
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
	cursor: pointer;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 3%;
	margin-left: 3%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px none hsla(0, 0%, 50%, 0.25);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom: 4px none transparent;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	border-bottom: 4px none #799165;
	background-color: rgba(255, 255, 255, 0.85);
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-toggle.w--open {
	color: #fff;
	cursor: pointer;
}

.nav-drop-list {
	display: none;
	width: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	display: block;
	max-width: 1500px;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: transparent;
	color: #fff;
}

.nav-drop-list-link a {
	display: block;
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0.5em 1em 0.5em 1rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	border: 0;
}

.nav-drop-list-link {
	padding: 0;
	margin: 0;
}

.nav-drop-list-link a:hover {
	width: auto;
	border-bottom-color: #e31d38;
	background-color: rgba(0, 0, 0, 0.3);
	color: #fff;
	text-shadow: 0 1px 31px #000;
}

.nav-drop-list-link a.w--current {
	background-color: rgba(0, 0, 0, 0.3);
	color: #fff;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 0.75rem 1.15rem;
	border-bottom: 3px solid #bababa;
	font-family: Oswald, sans-serif;
	color: #282c2d;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.nav-drop-toggle-link:hover {
	border-bottom-color: #898f90;
	background-color: transparent;
	color: #e31d38;
}

.nav-drop-toggle-link.active-item,
.nav-drop-toggle-link.w--current {
	border-right: 1px solid transparent;
	border-bottom: 3px solid #898f90;
	border-left: 1px solid transparent;
	background-color: transparent;
	color: #e31d38;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	height: 100%;
	max-width: 1700px;
	min-width: 900px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.5rem;
	line-height: 1.5em;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.secondary-header-item-cta-link {
	margin-left: 1rem;
	padding: 0.75em 3em 0.75em 1.5em;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url('/assets/images/keyboard-right-arrow-button-1.svg');
	background-position: 85% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	cursor: pointer;
}

.header-search-wrap {
	display: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	float: right;
}

.header-search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: hsla(0, 0%, 100%, 0.18);
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.nav-drop-list-inside-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-bottom: 1rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.footer-contact-label {
	display: inline-block;
	min-width: 64px;
	padding-left: 3.7rem;
	background-image: url('/assets/images/footer-icons-04.svg');
	background-position: 14px 50%;
	background-size: 28px 28px;
	background-repeat: no-repeat;
	color: #fff;
	transition: all 300ms ease;
}

.footer-contact-label:hover {
	background-image: url('/assets/images/footer-icons-08.svg');
	background-size: 28px 28px;
	color: #dadcdd;
}

.footer-contact-label.fax {
	background-color: transparent;
	background-image: url('/assets/images/footer-icons-05.svg');
	background-size: 32px 32px;
}

.footer-contact-label.fax:hover {
	background-image: url('/assets/images/footer-icons-09.svg');
	background-size: 32px 32px;
}

.footer-contact-label.email-us {
	background-color: transparent;
	background-image: url('/assets/images/footer-icons-06.svg');
	background-size: 30px 30px;
}

.footer-contact-label.email-us:hover {
	background-image: url('/assets/images/footer-icons-10.svg');
	background-size: 30px 30px;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.nav-drop-list-new {
	overflow: hidden;
}

.nav-drop-list-new.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
}

.nav-drop-list-element {
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: 3;
	display: none;
	overflow: hidden;
	width: 100%;
	min-width: 200px;
	margin: 0px;
	padding: 0.5rem;
	background-color: #000;
	box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.25);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-drop-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.secondary-header-item-text {
	float: left;
}

.secondary-header-item-icon {
	width: 1em;
	height: 1em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.footer-container {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	margin-right: auto;
	margin-left: auto;
}

.main-content-wrapper.inside-left {
	width: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.div-block {
	display: none;
}

.main-content-container {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.heading {
	font-size: 0.825rem;
}

.heading-2 {
	font-size: 0.95rem;
}

.nav-dropdown-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #282c2d;
}

.patient-portal-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 130px;
	height: 31px;
	margin-top: 0.45rem;
	margin-bottom: 0.45rem;
	padding: 0.3rem 2rem 0.3rem 0.65rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: 3px;
	background-color: #00214b;
	background-image: url('/assets/images/patient_portal_secure_icon.svg');
	background-position: 92% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-align: center;
}

.patient-portal-button:hover {
	border: 2px solid #54c4c4;
	background-color: transparent;
	background-image: url('/assets/images/patient_portal_secure_icon_hover-07.svg');
	background-position: 92% 50%;
	background-size: 12px;
	color: #fff;
}

.paragraph {
	margin-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 3.7rem;
	background-color: #474a4c;
	background-image: url('/assets/images/footer-icons_03.svg');
	background-position: 14px 50%;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	color: #dadcdd;
	transition: all 300ms ease;
}

.paragraph:hover {
	background-image: url('/assets/images/footer-icons-07.svg');
	background-size: 30px;
}

.breadcrumb-current-page {
	color: #e31d38;
	line-height: 0.6875rem;
	letter-spacing: 1px;
}

.search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
}

.search-input {
	display: none;
}

.search-button {
	height: 100%;
	min-height: 40px;
	min-width: 40px;
	margin-right: 1rem;
	padding: 0.55rem 1.4rem;
	background-color: #586879;
	background-image: url('/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.search-button:hover {
	background-color: #838e99;
}

.main-nav-section {
	position: static;
	left: 0px;
	top: 0px;
	right: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.search-hidden-dropdown {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: hsla(0, 0%, 100%, 0.2);
}

.search-form-wrapper {
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0rem;
	padding-left: 0rem;
}

.search-field {
	width: 90%;
	height: 100%;
	min-height: 50px;
	margin-bottom: 0px;
	padding-right: 2rem;
	padding-left: 2rem;
	float: left;
	border-style: none;
	background-color: rgba(0, 0, 0, 0.08);
}

.search-field::-webkit-input-placeholder {
	color: #28313b;
	border: 0;
}

.search-field:-ms-input-placeholder {
	color: #28313b;
	border: 0;
}

.search-field::-ms-input-placeholder {
	color: #28313b;
	border: 0;
}

.search-field::placeholder {
	color: #28313b;
	border: 0;
}

.search-form-block {
	display: none;
	margin-bottom: 0px;
	background-color: #f1f1f1;
	opacity: 0;
}

.search-button-hidden {
	display: block;
	width: 10%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0.8rem 0rem;
	float: left;
	background-color: #277f8b;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 400;
	letter-spacing: 0.08rem;
	text-transform: none;
}

.search-button-hidden:hover {
	background-color: #586879;
	cursor: pointer;
}

.search-wrap-new {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
}

.navigation-section {
	position: static;
	z-index: 100000000000;
	width: 100%;
	height: 120px;
	min-height: 55px;
	background-color: #fff;
	box-shadow: 0 3px 28px -14px rgba(0, 0, 0, 0.5);
	-webkit-transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.logo-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 35%;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.chinese-button-wrap {
	position: absolute;
	top: 0px;
	right: 0px;
	width: auto;
	min-width: 4rem;
}

.html-embed-2 {
	display: none;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.hero-image-slider {
	height: 75vh;
	min-height: 500px;
}

.slider-dots {
	display: block;
	margin-bottom: 3.5rem;
	font-size: 0.65rem;
}

.left-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60px;
	height: 60px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100%;
	background-color: rgba(91, 94, 95, 0.3);
	color: #fff;
	font-weight: 700;
}

.left-arrow:hover {
	background-color: #e31d38;
	color: #fff;
	cursor: pointer;
}

.right-arrow-link {
	display: block;
	width: 60px;
	height: 60px;
	margin-right: 2rem;
	border-radius: 100%;
	background-color: transparent;
	box-shadow: 0 0 42px 0 hsla(189, 3%, 55%, 0.5);
	color: #fff;
	font-weight: 700;
}

.right-arrow-link:hover {
	background-color: #e31d38;
	cursor: pointer;
}

.hero-image {
	background-image: linear-gradient(315deg, rgba(0, 0, 0, 0.25), rgba(0, 42, 62, 0.35)), url('/assets/images/logo-swipes-01.svg'), url('/assets/images/dupaco_2_2000x1333.jpg');
	background-position: 0px 0px, 0px 100%, 50% 50%;
	background-size: auto, 1000px, cover;
	background-repeat: repeat, no-repeat, no-repeat;
}

.main-cb-wrapper {
	padding-top: 50px;
	background-color: hsla(180, 5%, 92%, 0.5);
	background-image: url('/assets/images/graph-paper-texture-white-02.svg');
	background-size: 107px 107px;
}

.mission-statement {
	padding-bottom: 1rem;
	font-family: Oswald, sans-serif;
	color: #000;
	font-size: 3rem;
	line-height: 3.4rem;
}

.projects-title-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
	min-width: 230px;
	padding: 0.75rem 4rem 1rem 1.2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 6px solid transparent;
	background-color: hsla(0, 0%, 100%, 0.95);
	background-image: url('/assets/images/keyboard-right-arrow-button-2.svg');
	background-position: 96% 53%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	font-family: Oswald, sans-serif;
	color: #282c2d;
	font-size: 2.85rem;
	line-height: 3rem;
	border: 0;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
}

.projects-title-link:hover {
	border-right-color: #dadcdd;
	width: 100%;
	color: #e31d38;
}

.projects-title-link.join-our-team {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 25%;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
}

.projects-title-link.join-our-team:hover {
	width: 50%;
}

.testimonal-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	padding: 4rem 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(200, 4%, 86%, 0.82);
}

.testimonal-wrapper._2 {
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: transparent;
	color: #fff;
}

.testimonial-content {
	width: 100%;
}

.quote-box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 800px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.quote-box._2 {
	padding: 2rem 0;
	border: 4px none #fff;
	background-color: hsla(0, 3%, 94%, 0.5);
}

.description-box,
.quote-text {
	width: 100%;
	padding: 0.5rem 6.5rem;
	color: #000;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}

.location-1-wrapper {
	width: 75%;
}

.facebook-link {
	width: 30px;
	height: 30px;
	padding: 1.25rem;
	border-radius: 2rem;
	background-color: hsla(0, 0%, 100%, 0.25);
	background-image: url('/assets/images/facebook_centered_wht.svg');
	background-position: 50% 50%;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	border: 0;
}

.facebook-link:hover {
	background-color: #e31d38;
	cursor: pointer;
}

.view-more-button {
	position: relative;
	z-index: 1;
	padding: 0.45rem 1.25rem;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border: 3px solid #e31d38;
	background-color: #fff;
	color: #282c2d;
	text-transform: uppercase;
}

.view-more-button:hover {
	color: #e31d38;
	border: 3px solid #e31d38;
}

.author-name,
.company,
.attribution {
	padding-bottom: 0.5rem;
	padding-left: 6.5rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #5b5e5f;
	font-weight: 500;
	text-transform: none;
}

.author-name {
	padding-bottom: 0;
}

.testimonials-item > *:last-child {
	padding-bottom: 0.5rem;
}

.fax {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 2.5rem;
	margin-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(91, 94, 95, 0.5);
	color: #fff;
}

.phone {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 2.5rem;
	margin-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(91, 94, 95, 0.5);
	color: #fff;
}

.email-us {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 2.5rem;
	margin-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(91, 94, 95, 0.5);
	color: #fff;
}

.current-projects-title {
	width: 200px;
	font-size: 2.75rem;
	line-height: 3.25rem;
	font-weight: normal;
}

.current-projects-title:hover {
	color: #e31d38;
	cursor: pointer;
}

.top-of-mission-statement-div {
	position: relative;
	bottom: 100px;
	height: 0px;
}

.testimonials-item,
.quote-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.left-arrow-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60px;
	height: 60px;
	margin-left: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100%;
	box-shadow: 0 0 42px 0 hsla(189, 3%, 55%, 0.5);
	color: #fff;
}

.left-arrow-link:hover {
	color: #e31d38;
	cursor: pointer;
}

.right-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60px;
	height: 60px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(91, 94, 95, 0.3);
	color: #fff;
	font-weight: 700;
}

.hero-image-2 {
	background-image: linear-gradient(315deg, rgba(0, 0, 0, 0.25), rgba(0, 42, 62, 0.35)), url('/assets/images/logo-swipes-01.svg'), url('/assets/images/dupaco_4_2000x1333.jpg');
	background-position: 0px 0px, 0px 100%, 50% 50%;
	background-size: auto, 1000px, cover;
	background-repeat: repeat, no-repeat, no-repeat;
}

.hero-image-3 {
	background-image: linear-gradient(315deg, rgba(0, 0, 0, 0.25), rgba(0, 42, 62, 0.35)), url('/assets/images/logo-swipes-01.svg'), url('/assets/images/dupaco_3_2000x1333.jpg');
	background-position: 0px 0px, 0px 100%, 50% 50%;
	background-size: auto, 1000px, cover;
	background-repeat: repeat, no-repeat, no-repeat;
}

.background-banner {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 200px;
	margin-top: 120px;
	background-image: url('/assets/images/careers_1900x1286.jpg');
	background-position: 50% 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

blockquote,
.block-quote {
	border-left-color: #e31d38;
}

.hr {
	width: 100%;
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: #cde5eb;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000011;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="new-nav-drop-list"] {
	height: 0px;
}

html.w-mod-js *[data-ix="slider-upward-shift"] {
	opacity: 1;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	.button.header-search-button {
		width: 5rem;
		min-height: 4rem;
		font-size: 1.125rem;
	}

	.container {
		max-width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.about-us {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		margin-top: 0px;
		padding: 1rem 1.75rem;
	}

	.container.projects {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		padding-right: 0px;
		padding-left: 0px;
		background-image: url('/assets/images/graph-paper-texture-2.svg');
		background-size: 500px;
	}

	.container.careers {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.testimonials-2 {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.careers-2 {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.inside-right {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.nav-container {
		width: 5rem;
		height: 100%;
		max-width: 100%;
		min-height: 63px;
		min-width: 60px;
		margin-right: auto;
		margin-left: auto;
		float: right;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.nav-link {
		display: block;
		width: 100%;
		height: auto;
		min-width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		margin-left: 0px;
		padding: 0.75rem 1rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-color: transparent;
		box-shadow: none;
		color: #fff;
		line-height: 1.375rem;
		font-weight: 400;
		text-align: left;
		text-transform: uppercase;
	}

	.nav-link:hover {
		background-color: #151515;
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
	}

	.nav-link.w--current {
		height: auto;
		margin-left: 0px;
		background-color: rgba(39, 127, 139, 0.15);
		box-shadow: none;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: none;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-menu-wrap {
		z-index: 1000;
		display: block;
		width: auto;
		height: 100%;
		min-width: 500px;
		margin-top: 0rem;
		padding-right: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		background-color: #282c2d;
		text-align: left;
	}

	.logo-garling-link {
		display: block;
		z-index: 1;
		margin-top: 13rem;
		margin-bottom: 1.25rem;
		padding-top: 0;
		font-size: 1.375rem;
	}

	.row {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.row.footer {
		width: 100%;
		padding-right: 1.75rem;
		padding-left: 1.75rem;
	}

	.row.about-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column {
		text-align: left;
	}

	.column.full.about-us {
		width: 100%;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.half._1 {
		padding: 1.75rem;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/assets/images/832105172-170667a.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/assets/images/832105172-170667a.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.column.half._2 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.column.half._2 {
		padding: 1.75rem;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/assets/images/marion_library.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/assets/images/marion_library.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.column.footer {
		text-align: right;
	}

	.column.full._1 {
		margin-bottom: 0rem;
		padding: 2rem 1.75rem;
	}

	.garling-logo {
		height: auto;
	}

	.menu-button {
		display: block;
		width: 3rem;
		height: 100%;
		min-height: 100%;
		min-width: 3rem;
		margin-right: auto;
		margin-left: auto;
		padding: 0px 0rem;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button.w--open {
		background-color: transparent;
		cursor: pointer;
	}

	.footer-signoff-section {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 0.25rem;
		text-align: left;
	}

	.footer-signoff-list {
		text-align: left;
	}

	.footer-signoff-grip {
		margin-top: 0px;
		float: none;
	}

	.footer-column-wrap {
		max-width: 57%;
	}

	.footer-column-title {
		font-size: 1.45rem;
		line-height: 1.85rem;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.header-contents-wrap {
		display: block;
		width: 100%;
		height: 110px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		background-color: rgba(255, 255, 255, 0.85);
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.header-menu-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 10%;
		height: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 100%;
		height: 4rem;
		margin-bottom: 0rem;
		padding-left: 3rem;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		font-size: 0.9375rem;
		line-height: 1.25em;
	}

	.form-field.header-search-field:focus {
		width: 100%;
	}

	.form-field.header-search-field::-webkit-input-placeholder {
		color: #fff;
		border: 0;
	}

	.form-field.header-search-field:-ms-input-placeholder {
		color: #fff;
		border: 0;
	}

	.form-field.header-search-field::-ms-input-placeholder {
		color: #fff;
		border: 0;
	}

	.form-field.header-search-field::placeholder {
		color: #fff;
		border: 0;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.inside-row.inside-right {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-column {
		margin-top: 3rem;
	}

	.right-sidebar-column.inside-right {
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.secondary-nav-icon {
		position: relative;
		z-index: 2;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-text {
		margin-right: 0.25em;
		margin-left: 0.25em;
	}

	.secondary-nav-text.indicator-text {
		position: absolute;
		left: 1.25em;
		top: 50%;
		display: none;
		margin-right: 0em;
		margin-left: 0rem;
		float: none;
		font-size: 0.75em;
		line-height: 1.25em;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 2em;
		padding-left: 2em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 1.25em;
		font-weight: 600;
		cursor: pointer;
	}

	.slider-section {
		position: relative;
		background-color: #f1f4ee;
	}

	.home-section {
		z-index: 998;
		padding-top: 0rem;
	}

	.home-content-box-wrap {
		width: 100%;
		padding-bottom: 0rem;
		float: left;
	}

	.home-content-box-wrap.products {
		width: 100%;
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.inside-content-beckground.inside-right {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-content-beckground.inside-both {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.menu-button-icon {
		display: inline-block;
		width: 100%;
		height: 100%;
		min-width: 30px;
		margin-right: auto;
		margin-left: auto;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
		color: #e31d38;
		font-size: 2rem;
		line-height: 4rem;
	}

	.menu-button-icon:hover {
		color: #898f90;
		cursor: pointer;
	}

	.menu-button-text {
		display: none;
		float: left;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.7rem;
		line-height: 4rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		width: 100%;
		height: 100%;
		max-width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.home-cbox-text-wrap {
		width: 100%;
		padding-top: 0rem;
		float: right;
	}

	.wide-background-block.content-box-background {
		padding-bottom: 0rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.left-nav-reveal-button {
		display: block;
		margin-bottom: 1.25em;
		padding: 1em 50px;
		background-color: #5b636b;
		background-image: url('/assets/images/menu_hamburger_stagger_wht.svg');
		background-position: 17px 50%;
		background-size: 19px;
		background-repeat: no-repeat;
		color: #b8c5d1;
		letter-spacing: 0.05em;
	}

	.left-nav-reveal-button:hover {
		color: #fff;
		cursor: pointer;
	}

	.left-nav-reveal-button:active {
		background-color: #50575e;
		cursor: pointer;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-drop-toggle:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-drop-toggle-link {
		display: block;
		width: 90%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1rem;
		float: left;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #fff;
		font-weight: 400;
		text-align: left;
	}

	.nav-drop-toggle-link:hover {
		border-bottom-color: rgba(140, 205, 214, 0.1);
		background-color: #151515;
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
	}

	.nav-drop-toggle-link.w--current {
		border-bottom: 1px solid transparent;
		background-color: #000;
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 110px;
		min-width: auto;
		padding: 0.5rem 0rem 0.5rem 1.75rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.secondary-header-item-wrap {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		font-size: 1.5rem;
	}

	.secondary-header-item-cta-link {
		margin-right: 1rem;
		margin-left: 1rem;
		font-size: 0.8125rem;
	}

	.header-search-wrap {
		width: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding: 0rem;
		float: none;
	}

	.header-search-form {
		width: 100%;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.mobile-search-close-icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		padding: 1px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-search-active-bknd {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.secondary-header-item-icon {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper.inside-left {
		width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.main-content-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.patient-portal-button {
		margin-top: 0.75rem;
	}

	.search-wrap {
		width: 100%;
	}

	.search-input {
		display: none;
		width: 100%;
		height: 46px;
		margin-bottom: 0px;
		padding-left: 2rem;
		float: left;
		background-color: #eee;
	}

	.search-input:focus {
		background-color: rgba(190, 242, 255, 0.2);
		margin-bottom: 0px;
	}

	.search-input::-webkit-input-placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-input:-ms-input-placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-input::-ms-input-placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-input::placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-button {
		width: 10%;
		height: 46px;
		max-width: 50px;
		margin-right: 0rem;
		background-color: #586879;
	}

	.search-button:hover {
		background-color: #838e99;
		cursor: pointer;
	}

	.search-form-wrapper {
		width: 100%;
		min-width: 768px;
	}

	.search-field {
		width: 86%;
	}

	.search-button-hidden {
		width: 14%;
		min-width: 100px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.search-wrap-new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 45px;
		margin-right: 2rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.link-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 10%;
		height: 45px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
	}

	.link-arrow-image {
		width: 100%;
		height: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
		border-left: 1px solid rgba(140, 205, 214, 0.1);
	}

	.link-arrow-image:hover {
		background-color: #151515;
		cursor: pointer;
	}

	.navigation-section {
		height: 110px;
	}

	.chinese-button-wrap {
		top: 0px;
		right: 0px;
		width: 6rem;
	}

	.hero-image-slider {
		height: 45vh;
	}

	.slider-dots {
		margin-bottom: 0rem;
	}

	.hero-image {
		background-size: auto, 700px, cover;
	}

	.main-cb-wrapper {
		padding-top: 0px;
	}

	.projects-title-link.new {
		top: auto;
		right: 0px;
		bottom: 0px;
		height: 50%;
		min-width: 215px;
		padding-right: 4.5rem;
		background-color: rgba(255, 255, 255, 0.85);
	}

	.projects-title-link.join-our-team {
		position: absolute;
		background-color: rgba(255, 255, 255, 0.85);
	}

	.testimonal-wrapper._2 {
		width: 100%;
		padding: 0rem 1.75rem;
	}

	.quote-box._2 {
		width: 100%;
	}

	.location-1-wrapper {
		width: 100%;
	}

	.current-projects-title {
		width: 200px;
		padding-right: 0.5rem;
		font-size: 2.5rem;
		line-height: 2.8rem;
	}

	.hero-image-2 {
		background-size: auto, 700px, cover;
	}

	.hero-image-3 {
		background-size: auto, 700px, cover;
	}

	.background-banner {
		margin-top: 110px;
	}

	.right-coloumn-div {
		padding-top: 1rem;
		border-top: 2px solid rgba(84, 159, 196, 0.35);
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		min-height: 3rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.about-us {
		min-width: auto;
		margin-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.container.projects {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		min-width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.careers {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		min-width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.testimonials-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		min-width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.careers-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		min-width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.inside-right {
		padding-right: 1.65rem;
		padding-left: 1.65rem;
	}

	.nav-section {
		height: auto;
		min-height: auto;
	}

	.nav-container {
		width: 4.5rem;
	}

	.nav-menu-wrap {
		min-width: 450px;
	}

	.logo-garling-link {
		width: 100%;
		min-width: auto;
		margin-right: 10rem;
		margin-left: 1.5rem;
		padding-top: 2rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.logo-garling-link.w--current {
		width: 100%;
		min-width: auto;
		margin-top: 11rem;
		margin-bottom: 1em;
		margin-left: 1.5rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.row {
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.row.footer {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.about-wrapper {
		padding-bottom: 1rem;
	}

	.column {
		width: 100%;
	}

	.column.signoff {
		width: 65%;
		text-align: center;
	}

	.column.full {
		width: 100%;
	}

	.column.half {
		width: 100%;
	}

	.column.half._1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 50%;
		padding: 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.column.half._2 {
		display: block;
		padding: 1.25rem 1.5rem;
	}

	.column.half._2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 50%;
		padding: 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.column.footer {
		width: 35%;
	}

	.column.full {
		width: 100%;
	}

	.column.full._1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 50%;
		padding: 1.25rem 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.garling-logo {
		width: 200px;
		min-width: 150px;
		margin-top: -3rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.menu-button {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.copyright-text {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-column-wrap {
		width: 100%;
		max-width: 100%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.footer-column-wrap._2 {
		width: 50%;
		padding-bottom: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-column-wrap._3 {
		width: 50%;
		padding-bottom: 0rem;
	}

	.footer-column-wrap.offices {
		width: 50%;
		padding-bottom: 0rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.header-contents-wrap {
		height: auto;
	}

	.form-field.header-search-field {
		height: 3.5rem;
	}

	.secondary-nav-list {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.section-wrap.inside-content-section {
		padding-top: 6rem;
	}

	.secondary-nav-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-list-link {
		padding-right: 1em;
		padding-left: 1em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
	}

	.home-content-box-wrap.products {
		width: 100%;
		height: 100%;
		min-height: 300px;
		margin-bottom: 0rem;
		padding-bottom: 0rem;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 0px;
		margin-left: 0px;
	}

	.menu-button-icon {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		line-height: 3rem;
	}

	.intro_text_style,
	.intro-text-style {
		font-size: 1.125em;
	}

	.home-cbox-text-wrap {
		min-width: 100%;
		float: none;
	}

	.home-cbox-text-wrap.about-us {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.wide-background-block.content-box-background {
		padding-bottom: 0rem;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.header-container {
		height: 100px;
		padding-left: 0rem;
	}

	.secondary-header-item-wrap {
		font-size: 1rem;
	}

	.header-content-right-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}

	.secondary-header-item-cta-link {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-top: 0.75em;
		padding-right: 2.35em;
		padding-left: 1.35em;
		background-position: 90% 50%;
		background-size: 8px 8px;
		font-size: 0.6875rem;
		line-height: 1rem;
	}

	.header-search-wrap {
		margin-top: 0rem;
	}

	.secondary-header-item-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper.inside-left {
		padding-right: 1.65rem;
		padding-left: 1.65rem;
	}

	.main-content-container {
		padding-right: 1.65rem;
		padding-left: 1.65rem;
	}

	.patient-portal-button {
		width: 121px;
		padding-right: 1.5rem;
		background-position: 94% 50%;
		font-size: 0.84rem;
	}

	.search-input {
		display: block;
		width: auto;
		height: 100%;
		min-height: 42px;
		min-width: 85%;
		padding-left: 1.5rem;
		background-color: #eee;
		color: #1b2f63;
	}

	.search-input:focus {
		background-color: #bef2ff;
		background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.75)), to(hsla(0, 0%, 100%, 0.75)));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.75), hsla(0, 0%, 100%, 0.75));
	}

	.search-input::-webkit-input-placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-input:-ms-input-placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-input::-ms-input-placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-input::placeholder {
		color: #1b2f63;
		border: 0;
	}

	.search-button:hover {
		background-color: #3aa1af;
		cursor: pointer;
	}

	.search-form-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 480px;
	}

	.search-field {
		width: 85%;
	}

	.search-button-hidden {
		width: 15%;
	}

	.search-wrap-new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 1.5rem;
	}

	.navigation-section {
		height: 100px;
	}

	.logo-div {
		width: 45%;
	}

	.chinese-button-wrap {
		text-align: center;
	}

	.hero-image-slider {
		height: 41vh;
		min-height: 310px;
	}

	.left-arrow {
		width: 50px;
		height: 50px;
		font-size: 1.75rem;
	}

	.right-arrow-link {
		width: 50px;
		height: 50px;
		background-color: transparent;
		font-size: 1.75rem;
	}

	.hero-image {
		background-size: auto, 600px, cover;
	}

	.main-cb-wrapper {
		padding-top: 0px;
	}

	.mission-statement {
		font-size: 2.75rem;
		line-height: 3.25rem;
	}

	.projects-title-link.new {
		top: auto;
		right: 0px;
		bottom: 0px;
		height: 50%;
	}

	.projects-title-link.join-our-team {
		top: auto;
		right: 0px;
		bottom: 0px;
		height: 50%;
	}

	.projects-title-link.join-our-team:hover {
		width: 100%;
	}

	.description-box,
	.quote-text {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.author-name,
	.company,
	.attribution {
		padding-left: 2rem;
	}

	.current-projects-title {
		width: 205px;
		font-size: 2.35rem;
		line-height: 2.6rem;
	}

	.left-arrow-link {
		width: 50px;
		height: 50px;
		margin-left: 1.5rem;
	}

	.right-arrow {
		width: 50px;
		height: 50px;
	}

	.hero-image-2 {
		background-size: auto, 600px, cover;
	}

	.hero-image-3 {
		background-size: auto, 600px, cover;
	}

	.background-banner {
		height: 150px;
		margin-top: 100px;
	}
}

@media (max-width: 479px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.625rem;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		width: 78px;
		min-height: 3rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.about-us {
		padding: 1rem 0rem;
	}

	.container.projects {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.container.careers {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.container.testimonials-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.container.careers-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.container.inside-right {
		padding-right: 1.35rem;
		padding-left: 1.35rem;
	}

	.nav-container {
		width: 3.5rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.nav-menu-wrap {
		width: 300px;
		min-width: auto;
	}

	.logo-garling-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 156px;
		margin: 3rem auto 1rem;
		padding: 1.75rem 1.25rem 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.logo-garling-link.w--current {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 156px;
		max-width: none;
		margin-top: 3rem;
		margin-bottom: 0rem;
		margin-left: auto;
		padding: 1.75rem 1.25rem 1rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.row {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.row.footer {
		width: 100%;
		padding-right: 1.25rem;
		padding-bottom: 1.5rem;
		padding-left: 1.25rem;
	}

	.row.about-wrapper {
		padding-bottom: 0rem;
	}

	.column {
		width: 100%;
		float: none;
	}

	.column.signoff {
		width: 100%;
		padding-bottom: 2rem;
	}

	.column.full {
		width: 100%;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.half {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.half._2 {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.column.half._2 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.column.footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.column.full {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.garling-logo {
		display: block;
		width: 115px;
		min-width: 125px;
		float: none;
	}

	.menu-button {
		min-height: auto;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.copyright-text {
		text-align: left;
	}

	.footer-signoff-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-signoff-list-item.last {
		padding-right: 0rem;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-column-wrap._2 {
		width: 100%;
	}

	.footer-column-wrap._3 {
		margin-bottom: 0rem;
	}

	.footer-column-wrap.offices {
		width: 100%;
		max-width: 100%;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-menu-wrap {
		width: 50%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.form-field.header-search-field {
		height: 3rem;
		padding-right: 28%;
		padding-left: 1.25rem;
	}

	.secondary-nav-list {
		max-width: 75%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.section-wrap.inside-content-section {
		width: 100%;
		padding-top: 5rem;
	}

	.secondary-nav-text {
		display: none;
	}

	.home-content-box-wrap {
		margin-bottom: 1rem;
		padding-bottom: 0rem;
		float: none;
	}

	.menu-button-icon {
		display: block;
		width: 100%;
		min-height: auto;
		margin-top: 0rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.menu-button-text {
		display: none;
	}

	.header-menu-inside-wrap {
		min-width: 60px;
	}

	.home-cbox-text-wrap {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		float: none;
	}

	.wide-background-block.content-box-background {
		padding-bottom: 0rem;
	}

	.footer-links-list {
		margin-bottom: 0rem;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.nav-drop-toggle-link {
		width: 84%;
		background-position: 95% 50%;
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 80px;
		padding-left: 1.35rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.secondary-header-item-text {
		display: none;
	}

	.footer-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-wrapper.inside-left {
		padding-right: 1.35rem;
		padding-left: 1.35rem;
	}

	.main-content-container {
		padding-right: 1.35rem;
		padding-left: 1.35rem;
	}

	.patient-portal-button.mobile {
		width: 136px;
		margin-top: 0rem;
		margin-right: 4rem;
		margin-bottom: 0rem;
		border-color: #54c4c4;
		background-color: transparent;
		background-image: url('/assets/images/patient_portal_secure_icon_hover-07.svg');
		background-position: 91% 50%;
		background-size: 14px;
		color: #277f8b;
		line-height: 1.05rem;
		font-weight: 600;
	}

	.patient-portal-button.mobile:hover {
		border-color: transparent;
		background-color: #176a99;
		background-image: url('/assets/images/patient_portal_secure_icon.svg');
		background-position: 91% 50%;
		background-size: 14px;
		color: #fff;
	}

	.paragraph {
		margin-right: 0rem;
	}

	.search-input {
		min-height: 45px;
		min-width: 81%;
		background-color: #eee;
	}

	.search-button {
		width: 8%;
		height: 40px;
		max-width: 45px;
		min-width: 30px;
	}

	.search-button:hover {
		cursor: pointer;
		background-color: #3aa1af;
	}

	.search-form-wrapper {
		height: 42px;
		min-width: 320px;
	}

	.search-field {
		width: 83%;
		min-height: 40px;
		padding-left: 1.25rem;
	}

	.search-button-hidden {
		width: 12%;
		min-height: 40px;
		min-width: 85px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.link-arrow {
		width: 15%;
	}

	.navigation-section {
		height: 80px;
	}

	.logo-div {
		display: block;
		width: 50%;
	}

	.hero-image-slider {
		height: 27vh;
		min-height: 205px;
	}

	.slider-dots {
		height: 3.5rem;
	}

	.right-arrow-link {
		top: 70%;
		background-color: rgba(0, 0, 0, 0.25);
	}

	.hero-image {
		background-size: auto, 400px, cover;
	}

	.mission-statement {
		font-size: 2.35rem;
		line-height: 2.85rem;
	}

	.projects-title-link {
		width: 100%;
	}

	.projects-title-link.new {
		width: 50%;
		max-height: 115px;
		min-width: 200px;
		background-color: rgba(255, 255, 255, 0.85);
	}

	.projects-title-link.join-our-team {
		max-height: 115px;
		min-width: 200px;
		background-color: rgba(255, 255, 255, 0.85);
	}

	.testimonal-wrapper._2 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.quote-box._2 {
		padding-top: 0.5rem;
		padding-bottom: 1.5rem;
	}

	.description-box,
	.quote-text {
		padding-right: 1.2rem;
		padding-bottom: 0.25rem;
		padding-left: 1.2rem;
		font-size: 1.25rem;
	}

	.location-1-wrapper {
		width: 100%;
	}

	.author-name,
	.company,
	.attribution {
		padding-bottom: 1rem;
		padding-left: 1.2rem;
	}

	.author-name {
		padding-bottom: 0;
	}

	.testimonials-item > *:last-of-type {
		padding-bottom: 1rem;
	}

	.fax {
		margin-right: 0rem;
	}

	.phone {
		margin-right: 0rem;
	}

	.email-us {
		margin-right: 0rem;
	}

	.current-projects-title {
		width: 200px;
		max-width: 175px;
		font-size: 2.199rem;
		line-height: 2.55rem;
	}

	.left-arrow-link {
		top: 70%;
		background-color: rgba(0, 0, 0, 0.25);
		color: #fff;
	}

	.left-arrow-link:hover {
		cursor: pointer;
		background-color: #e31d38;
	}

	.hero-image-2 {
		background-size: auto, 400px, cover;
	}

	.hero-image-3 {
		background-size: auto, 400px, cover;
	}

	.background-banner {
		height: 80px;
		margin-top: 80px;
	}
}

/***************************************
  Cutup styles
****************************************/

/***************************************
  Custom styles in html
****************************************/
/* Set root font size */
html {
	font-size: 16px;
}
/* Set font render */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}
/* Superscript */
sup {
	font-size: xx-small;
}
/* Clear input field styling */
input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
/* Footer phone number auto-styling on mobile devices */
.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	text-decoration: none;
	color: inherit;
}
/* Adjust heading styles when they're set as links */
xh1 > a {
	color: inherit;
	cursor: pointer;
}

xh1 > a:hover {
	color: inherit;
	cursor: pointer;
}
/* Text selection style for Mozilla based browsers */
::-moz-selection {
	background-color: #e3e3e3;
	color: #000;
	border: 0;
}
/* Text seclection style for Safari browser */
::selection {
	background-color: #e3e3e3;
	color: #000;
	border: 0;
}

/* breakpoints */
[data-breakpoints] {
	display: none;
}

@media screen and ( max-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block;
	}
}

@media screen and ( max-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block;
	}
}

@media screen and ( max-width: 479px ) {
	[data-breakpoints*="xs,sm"] {
		display: block;
	}
}


/***************************************
  Header
****************************************/
#printHeader,
#printFooter {
	display: none;
}

.mobile-navigation-menu {
	display: none;
}

/***************************************
  accessible menu
****************************************/
.nav-drop-list.w-dropdown-list {
	display: block;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 300ms ease 0s; /* Safari */
	transition: opacity 300ms ease 0s;
	left: 0;
}

.open .nav-drop-list.w-dropdown-list {
	height: auto;
	opacity: 1;
}

.nav-drop-list-inside-wrap {
	height: inherit;
	overflow: hidden;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 47px;
	background: #282c2d;
}

.nav-drop-list-link:last-of-type {
	margin-bottom: 0;
}

/***************************************
  mmenu
****************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mm-menu {
		background: #282c2d;
	}

	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1rem;
		float: none;
		box-shadow: none;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		text-transform: uppercase;
		background: #282c2d;
	}

	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: transparent;
		cursor: pointer;
	}

	.mm-menu a.nav-link.w--current,
	.mm-menu a.w--current {
		border-bottom: 1px solid rgba(140, 205, 214, 0.23);
		background-color: #000;
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
		cursor: pointer;
	}

	.mm-menu .mm-panel ul.mm-listview li.active-item a:not(.mm-btn_next) {
		background-color: #000;
		box-shadow: inset 4px 0 0 0 #e31d38;
		border-bottom: 1px solid rgba(140, 205, 214, 0.3);
	}

	.mm-menu .mm-panel ul.mm-listview li.active-item .mm-btn_next:not(.mm-btn_fullwidth) {
		background-color: #000;
		border-bottom: 1px solid rgba(140, 205, 214, 0.3);
		border-left: 1px solid rgba(140, 205, 214, 0.23);
	}

	.mm-menu .mm-panel ul.mm-listview li.active-item a.mm-btn_next:hover {
		background-color: #000;
		cursor: pointer;
	}

	.mm-menu a.nav-link.w--current:hover {
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
		background-color: #000;
		border-bottom: 1px solid rgba(140, 205, 214, 0.23);
		cursor: pointer;
	}

	.mm-menu a:hover:not(.mm-btn_next):not(.mm-navbar__title):not(.mm-btn_prev) {
		background-color: #151515;
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
		border-bottom: 1px solid rgba(140, 205, 214, 0.23);
		cursor: pointer;
	}

	.mm-menu a.active-item:hover:not(.mm-btn_next):not(.mm-navbar__title):not(.mm-btn_prev) {
		background-color: #151515;
		box-shadow: inset 4px 0 0 0 #e31d38;
	}

	.mm-menu .mm-panel ul.mm-listview li.active-item a:not(.mm-btn_next):hover {
		border-bottom: 1px solid rgba(140, 205, 214, 0.23);
		background-color: #000;
		box-shadow: inset 4px 0 0 0 #e31d38;
		color: #fff;
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: rgba(140, 205, 214, 0.1);
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #fff;
		-webkit-transition: border-color 300ms;
		transition: border-color 300ms;
	}

	.mm-menu .mm-btn:hover:after,
	.mm-menu .mm-btn:hover:before,
	.mm-menu .mm-listview .mm-btn_next:hover:after {
		border-color: #fff;
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: #151515;
		color: #fff;
		cursor: pointer;
		border-bottom: 1px solid rgba(140, 205, 214, 0.23);
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		padding: 1px 10px 0 40px;
		height: 61px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		transition: color 300ms;
		height: 61px;
		border-bottom: 0 none !important;
		background-color: transparent;
	}

	.mm-menu a.mm-navbar__title {
		font-size: 1rem;
		line-height: 2.15rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
	}
}
/* end of mmenu */

/***************************************
  homepage
****************************************/
/* slideshow */
.w-slide {
	/* background-position: 0px 0px, 0px 100%, 50% 50%;
  background-size: auto, 1000px, cover;
  background-repeat: repeat, no-repeat, no-repeat; */
	background-position: center;
	background-size: 100%;
	background-repeat: no-repeat;
}
/***************************************
  table styles
****************************************/
td img {
	max-width: none;
}

.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: transparent;
	border-spacing: 0px;
}
/* Vertical Cell Borders */

.styled th,
.styled td {
	border-right: 1px solid white;
}

.styled tr th:last-child,
.styled tr td:last-child {
	border-right: none;
}
/* Table Header - aka Table Title */

.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #e31d38;
	color: white;
}
/* Optional Column Headers */

.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #8e1822;
	color: white;
}
/* This code controls any visual distinctions for link decoration */

.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: none;
}

.styled th a:hover {
	color: inherit;
	text-decoration: none;
}
/* Overall Data Characteristics */

.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}
/* Even Row Data */

.styled.striped tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}
/* Odd Row Data */

.styled.striped tr:nth-child(odd) {
	background: transparent;
}


.footer-link.footer-list-item-link {
	text-transform: capitalize;
}

.phone-mobile {
	display: none;
}

/* contact information */
.phone {
	padding: 0;
}

.footer-contact-label.phone-mobile,
.footer-contact-label.phone-desktop {
	height: 100%;
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.footer-contact-label.phone-mobile {
	text-decoration: underline !important;
	border-bottom: 0;
}

a.footer-contact-label.email-us,
.footer-contact-label.fax {
	width: 100%;
	margin-right: 0;
	border-bottom: 0;
}

/* grip logo */
div#GRIPFooterLogo {
	padding-top: 0 !important;
	float: right;
	transition: all 300ms ease;
	opacity: 0.6;
}

div#GRIPFooterLogo:hover {
	opacity: 1;
}

div#GRIPFooterLogo a {
	border: 0;
}

div#GRIPFooterLogoImg {
	margin-top: -25px !important;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline;
	}
}

/***************************************
  Right Sidebar & left sidebar
****************************************/
.left-sidebar .box,
.right-coloumn-div .box {
	margin-bottom: 0.75rem;
	padding-top: 0.75rem;
}

.left-sidebar .box h1,
.left-sidebar .box h2,
.left-sidebar .box h3,
.left-sidebar .box h4,
.right-coloumn-div .box h1,
.right-coloumn-div .box h2,
.right-coloumn-div .box h3,
.right-coloumn-div .box h4,
#events_module.cms_list .cms_list_item .cms_date {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
}

.left-sidebar .box p.image-ad a,
.right-coloumn-div .box p.image-ad a {
	border: 0;
}

.left-sidebar .box .contentbox_item_image,
.right-coloumn-div .box .contentbox_item_image {
	width: 24px;
}

.left-sidebar .box.documents td:first-child,
.right-coloumn-div .box.documents td:first-child {
	padding-right: 7px;
}

.left-sidebar .box.tools td:first-child img,
.right-coloumn-div .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.left-sidebar .box,
	.right-coloumn-div .box {
		margin-left: 0em;
	}
}

/***************************************
  modules
****************************************/

/****************** Accounts ************************/
/***************************************************/
/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 340px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #ccc;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #144c8d;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #eb0000;
	font-style: italic;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}

	#account_addresses .address_section td a + br {
		display: block;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/****************** Events *********************/
/***********************************************/
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	display: inline-block;
	width: calc(100% - 4px);
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_date.cms_date_with_time_comments {
	margin-bottom: 10px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
	font-size: 1rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .event-category-label {
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

.primary.event-registration-button {
	margin-top: 10px;
}

#events_module.cms_list .fc-list-item-title a {
	text-decoration: none;
	border-bottom: 1px solid rgb(68, 68, 68, 0.65);
}

#events_module.cms_list .fc-list-item td a:hover,
#events_module.cms_list .fc-list-item-title a:hover {
	border-bottom: 1px solid transparent;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0 0 7px;
	list-style-type: none;
}

.box.tools .content .contentbox_item form .primary {
	margin-top: 0;
}

#faqs_module.cms_list .cms_list_item .description > *:last-child,
#faqs_module.cms_list .cms_list_subitem .description > *:last-child {
	margin-bottom: 0;
}

/****************** Jobs ***********************/
/***********************************************/
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #ccc;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: middle;
}

#jobs_module.cms_form .cms_required,
#jobs_module.cms_form .cms_non_required {
	padding: 0;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.375rem 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0;
}

/* sidebar job search filtering */
.right-sidebar .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-sidebar .box #frmDepartments #Department {
	width: 100%;
}

.right-sidebar .box #frmDepartments button[type="submit"].primary {
	padding: 9px 10px !important;
	display: inline;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/*************** Submission Forms ***************/
/************************************************/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_required em {
	display: none;
	color: #eb0000;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	content: "*";
	color: #eb0000;
	padding-left: 5px;
	font-style: italic;
	font-size: 1.2rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 1px solid rgb(51, 51, 51, 0.65);
}

#submissionforms_module.cms_form .cms_steps_wrapper span a:hover {
	border-bottom: 1px solid transparent;
}

#submissionforms_module.cms_form .sigWrapper.current {
	height: 102px;
	width: 100%;
	max-width: 302px;
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_required,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid #ccc;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
	transform: translate(0px, -60%);
}

#submissionforms_module .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
	color: #363a3e;
}

#submissionforms_module .ui-form-label span {
	color: #363a3e;
	font-weight: 400;
}

#submissionforms_module .ui-form-label em {
	color: #ff0000;
	font-style: italic;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 26%;
}

.module-forms.confirmation .step-title {
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	color: #282c2d;
	font-size: 2.25rem;
	line-height: 2.9rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

.module-forms.confirmation pre {
	overflow: hidden;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last {
	height: 27px;
}

.module-forms .container-fields-wrapper {
	padding: 15px 20px;
	margin: 0;
	border: 1px solid rgba(51, 51, 51, 0.5);
	margin-bottom: 10px;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_required,
	#submissionforms_module.cms_form .cms_label {
		padding-bottom: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding-left: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after,
	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #eb0000;
		padding-left: 5px;
	}

	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 37%;
		left: 4px;
	}
}

/****************** News ***********************/
/***********************************************/
.box.archive {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 1px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module.cms_list .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#news_module.cms_list article time {
	font-weight: 600;
}

#news_module.cms_list article h2 {
	padding-top: 0;
}

.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

.archive_links {
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 991px) {
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}

	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}

/****************** Partners ********************/
/***********************************************/
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border-bottom-width: 0;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

.box.partner-document .contentbox_item_image {
	width: 35px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/****************** Photo Albums ****************/
/***********************************************/
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_list_item .cms_title h3 {
	padding-top: 0;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
	padding-bottom: 5px;
}

#testimonials_module.cms_list .cms_list_item .cms_bottom {
	padding-top: 10px;
	padding-bottom: 0px;
}

#testimonials_module.cms_list .testimonial-related-products {
	line-height: 1.5rem;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

div#testimonials_module_contentbox {
	width: 100%;
}

div#testimonials_module_contentbox > p {
	width: 100%;
	padding: 1.5rem 3rem;
	color: #000;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}
/****************** Newsletters ********************/
/***************************************************/
#newsletters_module.list h3 {
	padding-top: 0;
	margin-top: 0;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url("/core/public/shared/assets/images/required.gif");
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
		padding-bottom: 15px;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/***************************************
  homepage contentboxes
****************************************/
/* events */
#events_module {
	color: #444;
	display: block;
}

#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 1.5em;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
	padding-top: 2px;
	font-weight: 600;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

#events_module.homepage_contentbox .event_list table tr td.title a {
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 600;
	border-bottom: 1px solid transparent;
	color: #5b5e5f;
	text-decoration: none;
	border-bottom-color: rgba(140, 205, 214, 0.5);
}

#events_module.homepage_contentbox .event_list table tr td.title a:hover {
	color: #e31d38;
	cursor: pointer;
}

#events_module.homepage_contentbox .cms_description p {
	margin-bottom: 0;
}

#events_module.homepage_contentbox .footer {
	display: none;
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox .event_list table tr td.date,
	#events_module.homepage_contentbox .event_list table tr td.title {
		display: block;
		width: 100%;
	}
}

/* news  */
#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 20px;
}

#news_module.homepage_contentbox .cms_metadata1.cms_title h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.125rem;
	line-height: 1.6rem;
}

#news_module.homepage_contentbox .cms_metadata1.cms_title h3 a {
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 600;
	border-bottom: 1px solid rgba(140, 205, 214, 0.5);
	color: #5b5e5f;
	text-decoration: none;
}

#news_module.homepage_contentbox .cms_metadata1.cms_title h3 a:hover {
	color: #e31d38;
	cursor: pointer;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1rem;
	line-height: 1.6em;
	color: #333;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 5px;
}

#news_module.homepage_contentbox.displaymode_list > a {
	display: none;
}

/* partners */
#partners_module.homepage_contentbox.displaymode_list {
	margin-top: 15px;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	margin-bottom: 1.5rem;
}

#partners_module.homepage_contentbox.displaymode_list .cms_title a {
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 600;
	border-bottom: 1px solid rgba(140, 205, 214, 0.5);
	color: #5b5e5f;
	text-decoration: none;
}

#partners_module.homepage_contentbox.displaymode_list .cms_title a:hover {
	color: #e31d38;
	cursor: pointer;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item .cms_metadata1.cms_title .cms_content {
	color: #313131;
	font-size: 1.125rem;
	line-height: 1.6rem;
	display: block;
}

#partners_module .partners-cycle-carousel-next,
#partners_module .arrow-icon-left {
	color: #444;
	cursor: pointer;
}

#partners_module .partners-cycle-carousel-previous:hover .arrow-icon-left,
#partners_module .partners-cycle-carousel-next:hover .arrow-icon-right {
	color: #e31d38;
	cursor: pointer;
}

/* submission forms */
div#submissionforms_module {
	color: #444;
	display: block;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox + script + .home-content-box-learn-more {
	display: none;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/***************************************
  IE11 Fixes
****************************************/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.paragraph:hover {
		background-size: 30px 30px;
	}
}

/***************************************
  iOS Fixes
****************************************/
@supports (-webkit-overflow-scrolling: touch) {

	@media (max-width: 767px) {
		.header-menu-wrap {
			width: 49%;
		}
	}
}
